import { getData, postData, putData, deleteData } from '../api.js';

const { REACT_APP_NEWSAPI_BASE_URL, REACT_APP_NEWSAPI_KEY } = process.env;

export const getTopHeadlines = async () => {
  const url = `${REACT_APP_NEWSAPI_BASE_URL}top-headlines?country=us&apiKey=${REACT_APP_NEWSAPI_KEY}`
  try {
    const data = await getData(url);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getBusinessNews = async () => {
  const url = `${REACT_APP_NEWSAPI_BASE_URL}top-headlines?category=business&country=us&apiKey=${REACT_APP_NEWSAPI_KEY}`;
  try {
    const data = await getData(url);
    return data;
  } catch (error) {
    console.error(error);
  }
};


